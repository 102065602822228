import { hash } from '@/utility/strings';
import { Adb } from '@/api/adb/adb';
import { Configuration } from '@/entities/configuration';
import { Device } from '@/entities/device';

const alphaSort = (a: string, b: string): number => (a < b ? -1 : 1);

export function filterFiles(
    files: string[],
    exists: string[],
    override: boolean,
    overrideLargesFiles: boolean
): string[] {
    if (override && overrideLargesFiles) return files;
    if (override) return files.filter((file) => !isLargeFile(file) || exists.indexOf(file) == -1);
    return files.filter((file) => exists.indexOf(file) == -1);
}

function isLargeFile(file: string): boolean {
    const ext = file.split('.').pop()!.trim();
    return ['zip', 'mp4', 'tbe'].indexOf(ext) > -1;
}

export function hashConfig(map: string[]): number {
    return hash(map.join(','));
}

export async function configMap(configuration: Configuration): Promise<string[]> {
    const h: string[] = [configuration.name];
    for (const props in configuration.props) {
        h.push(props, configuration.props[props].toString());
    }
    if (configuration.files) {
        for (const file of configuration.files) {
            if (file.files.length > 0) {
                h.push(...file.files.sort(alphaSort));
            }
        }
    }
    if (configuration?.applications.packages) {
        for (const app of configuration.applications.packages) {
            h.push(app.pkg);
            if (app.files) {
                for (const file of app.files) {
                    h.push(...file.files.sort(alphaSort));
                }
            }
            // if (app.permissions) {
            //     h.push(...app.permissions.filter(filterPerms).sort(alphaSort));
            // }
        }
    }
    return h;
}

export async function deviceMap(
    device: Device,
    name: string,
    configuration: Configuration
): Promise<string[]> {
    if (device == null) throw 'Device is null';
    if (configuration == null) throw 'Configuration is null';
    const h: string[] = [name];
    const adb = new Adb(device.serial);
    try {
        await adb.connect();
        for (const props in configuration.props) {
            const old = await device.getProps(adb, props);
            h.push(props, old.toString());
        }
        if (configuration.files) {
            for (const files of configuration.files) {
                const installedFiles = (await device.getFiles(adb, files.path)).filter(
                    (f) => files.files.indexOf(f) > -1
                );
                h.push(...installedFiles.sort(alphaSort));
            }
        }
        for (const app of configuration.applications.packages) {
            if (device.isInstalled(app.pkg)) {
                h.push(app.pkg);
            }
            if (app.files) {
                for (const files of app.files) {
                    const installedFiles = (await device.getFiles(adb, files.path)).filter(
                        (f) => files.files.indexOf(f) > -1
                    );
                    h.push(...installedFiles.sort(alphaSort));
                }
            }
            // const pkg = device.packages.find((p) => p.name == app.pkg);
            // if (app.permissions != null && pkg) {
            //     const installedPerms = app.permissions.filter(
            //         (p) => pkg.permissions.indexOf(p) > -1
            //     );
            //     // h.push(...installedPerms.filter(filterPerms).sort(alphaSort));
            // }
        }
        if (device.deviceAdmin) h.push(device.deviceAdmin);
    } catch (e) {
        console.error(e);
    } finally {
        await adb.close();
    }
    return h;
}

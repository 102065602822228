import App from './App.vue';
import { createApp } from 'vue';
import router from './router/index';

function createVue(): void {
    const app = createApp(App).use(router);
    app.config.errorHandler = (err) => {
        console.error('error', err);
    };
    app.mount('#app');
}

createVue();

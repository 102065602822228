import { Options, Vue } from 'vue-class-component';
import { getEnv } from '@/utility/env';
import { setup } from '@/api/log/run';

@Options({ name: 'App' })
export default class App extends Vue {
    version = getEnv('APP_VERSION') ?? 'localhost';
    build = getEnv('APP_BUILD');

    mounted() {
        setup();
    }
}

export const getEnv = (name: string): string => {
    return window?.env?.[name] || process.env[`VUE_${name}`] || defaults[name];
};

const defaults: { [key: string]: string } = {
    APP_RUM_TOKEN: '',
    APP_LOGS_TOKEN: '',
    APP_ENV: 'development',
    APP_NAME: 'adb-development',
    APP_URL: 'http://localhost:8079',
    APP_VERSION: 'local',
    APP_BUILD: 'internal'
};

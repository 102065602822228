import { getEnv } from '@/utility/env';

export const filesUrl = 'https://files.butterfly-tx.com/setup';

export function getConfigUrl() {
    const env = getEnv('APP_ENV');
    if (['production', 'development'].includes(env))
        return `${filesUrl}/configs-${env}.json?` + Math.random();
    return `${filesUrl}/configs.json?` + Math.random();
}

import { Options, Vue } from 'vue-class-component';
import { AdbDaemonWebUsbDeviceManager } from '@yume-chan/adb-daemon-webusb';
import { Device, DeviceType } from '@/entities/device';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Config, Configuration } from '@/entities/configuration';
import { Adb } from '@/api/adb/adb';
import { hash } from '@/utility/strings';
import { Log } from '@/api/log/log';
import { timeConversion } from '@/utility/time';
import { getConfigUrl } from '@/utility/config';

@Options({
    props: {
        serial: String
    },
    methods: {
        check() {
            return faCheckCircle;
        },
        warning() {
            return faBolt;
        },
        info() {
            return faCircleInfo;
        }
    },
    components: { FontAwesomeIcon }
})
export default class Phone extends Vue {
    public declare serial: string;
    public declare check: () => IconDefinition;
    public declare warning: () => IconDefinition;
    public device: Device | null = null;
    public configuration: Configuration | null = null;
    public deviceLoaded = false;
    public deviceReady = false;
    public configApplied = false;
    public status = 'Chargement de la configuration';
    public error: string | null = null;
    public progress: number | null = null;
    public override = false;

    mounted() {
        this.getDevice().catch(console.error);
    }

    unmounted() {
        this.override = false;
    }

    updateProgress(e: ProgressEvent) {
        const pc = (e.loaded * 100) / e.total;
        this.progress = pc == 100 ? null : pc;
    }

    async loadDeviceInfo() {
        if (this.device == null) return;
        await this.device.loadInfo();
        await this.loadConfig(DeviceType.Phone);
        await this.loadPackagesInfo();
        await this.deviceIsReady();
    }

    async loadConfig(deviceType: DeviceType) {
        try {
            const resp = await fetch(getConfigUrl());
            const config = (await resp.json()) as Config;
            this.configuration = config.configs.find((c) => c.deviceType == deviceType) || null;
        } catch (e: any) {
            this.error = 'Impossible de charger les configurations';
        }
    }

    async loadPackagesInfo() {
        if (!this.device || !this.configuration) return;
        const pkgs = this.configuration.applications.packages.map((p) => p.pkg);
        await this.device.loadPackagesInfo(pkgs);
    }

    async deviceIsReady() {
        if (!this.device || !this.configuration) return;
        const configHash = this.configHash();
        const deviceHash = await this.deviceHash();
        this.configApplied = configHash == deviceHash;
        this.deviceReady = this.configApplied;
    }

    async setupDevice() {
        if (this.device == null) throw 'Device is null';
        if (this.configuration == null) throw 'Configuration is null';
        const startTime = Date.now();
        Log.debug({
            action: 'setup start',
            serial: this.device.serial,
            configuration: this.configuration.name
        });
        this.status = 'Configuration en cours';
        const adb = new Adb(this.device.serial);
        try {
            await adb.connect();
            if (this.device.deviceAdmin) {
                await this.device.configureDeviceAdmin(adb, this.device.deviceAdmin, false);
            }
            for (const props in this.configuration.props) {
                this.status = "Configuration d'Android";
                const old = await this.device.getProps(adb, props);
                const value = this.configuration.props[props];
                if (this.override || old != value) await this.device.setProps(adb, props, value);
            }
            if (this.configuration.files) {
                for (const file of this.configuration.files) {
                    const exists = this.override ? [] : await this.device.getFiles(adb, file.path);
                    const files = file.files.filter((f) => exists.indexOf(f) == -1);
                    if (files.length > 0) {
                        await adb.shell(`mkdir -p ${file.path}`);
                        for (const f of files) {
                            this.status = `Copie de ${f.split('/').pop()}`;
                            const url = `${file.baseUrl}/${f}`;
                            adb.addEventListener('progress', this.updateProgress);
                            await adb.stream(url, file.path);
                            adb.removeEventListener('progress', this.updateProgress);
                            this.progress = null;
                        }
                    }
                }
            }
            const baseUrl = this.configuration.applications.baseUrl;
            for (const app of this.configuration.applications.packages) {
                //console.log(app.pkg, this.device.isInstalled(app.pkg));
                if (this.override || !this.device.isInstalled(app.pkg)) {
                    this.status = `Installation de ${app.name}`;
                    adb.addEventListener('progress', this.updateProgress);
                    try {
                        await this.device.installBundle(
                            adb,
                            baseUrl,
                            app.pkg,
                            app.apks,
                            app.adminReceiver != ''
                        );
                    } catch (e) {
                        if ('install failed' == `${e}`) {
                            await this.device.uninstall(adb, app.pkg);
                            await this.device.installBundle(
                                adb,
                                baseUrl,
                                app.pkg,
                                app.apks,
                                app.adminReceiver != ''
                            );
                        }
                    }
                    adb.removeEventListener('progress', this.updateProgress);
                    this.progress = null;
                }
                const pkg = this.device.packages.find((p) => p.name == app.pkg);
                const installedPerms = this.override || !pkg ? [] : pkg.permissions;
                if (app.permissions) {
                    const perms = app.permissions.filter((p) => installedPerms.indexOf(p) == -1);
                    for (const perm of perms) {
                        this.status = `Configuration des permissions de ${app.name}`;
                        await this.device.setPermission(adb, app.pkg, perm);
                    }
                }
                if (app.files) {
                    for (const files of app.files) {
                        const installedFiles = this.override
                            ? []
                            : await this.device.getFiles(adb, files.path);
                        const missingFiles = files.files.filter(
                            (f) => installedFiles.indexOf(f) == -1
                        );
                        if (missingFiles.length > 0) {
                            await adb.shell(`mkdir -p ${files.path}`);
                            for (const file of missingFiles) {
                                this.status = `Copie de ${file.split('/').pop()}`;
                                const url = `${files.baseUrl}/${file}`;
                                adb.addEventListener('progress', this.updateProgress);
                                await adb.stream(url, files.path);
                                adb.removeEventListener('progress', this.updateProgress);
                                this.progress = null;
                            }
                        }
                    }
                }
            }

            const deviceAdmin = this.configuration.applications.packages.find(
                (p) => !!p.adminReceiver
            );
            if (deviceAdmin) {
                const rcv = `${deviceAdmin.pkg}/.${deviceAdmin.adminReceiver}`;
                await this.device.configureDeviceAdmin(adb, rcv, true);
            }

            const launcher = this.configuration.applications.packages.find((p) => !!p.launcher);
            if (launcher) {
                const activity = `${launcher.pkg}/.${launcher.launcher}`;
                await this.device.configureLauncher(adb, activity, true);
            }
        } catch (e) {
            Log.debug({
                action: 'setup error',
                serial: this.device.serial,
                configuration: this.configuration.name,
                error: `${e}`,
                duration: timeConversion(Date.now() - startTime)
            });
            console.error(e);
        } finally {
            Log.debug({
                action: 'setup complete',
                serial: this.device.serial,
                configuration: this.configuration.name,
                duration: timeConversion(Date.now() - startTime)
            });
            this.status = '';
        }

        try {
            await adb.shell('reboot');
            await adb.close();
        } catch (e) {
            console.error(e);
        }
    }

    async resetDevice() {
        if (this.device == null) throw 'Device is null';
        if (this.configuration == null) throw 'Configuration is null';
        this.deviceReady = false;
    }

    async reboot() {
        if (!this.device) return;
        await this.device.reboot();
    }

    private async getDevice() {
        try {
            const manager = AdbDaemonWebUsbDeviceManager.BROWSER;
            if (!manager) throw "Le périphérique USB n'est pas disponible";
            const device = (await manager.getDevices()).find((d) => d.serial == this.serial);
            if (device != null) {
                this.device = new Device(device.name, device.serial, device);
                await this.loadDeviceInfo();
            }
        } catch (e) {
            Log.error({ action: 'load', serial: this.serial, error: `${e}` });
            this.device = null;
        } finally {
            this.deviceLoaded = true;
            this.status = '';
        }
    }

    private configHash(): number {
        if (this.configuration == null) throw 'Configuration is null';
        const h: string[] = [];
        for (const props in this.configuration.props) {
            // console.log('props', props, this.configuration.props[props].toString());
            h.push(props, this.configuration.props[props].toString());
        }
        if (this.configuration.files) {
            for (const file of this.configuration.files) {
                if (file.files.length > 0) {
                    // console.log('files', ...file.files);
                    h.push(...file.files.sort(this.alphaSort));
                }
            }
        }
        if (this.configuration?.applications.packages) {
            for (const app of this.configuration?.applications.packages) {
                // console.log('pkg', app.pkg);
                h.push(app.pkg);
                if (app.files) {
                    for (const file of app.files) {
                        // console.log('- files', ...app.files?.files);
                        h.push(...file.files.sort(this.alphaSort));
                    }
                }
                if (app.permissions) {
                    // console.log('- permissions', ...app.permissions);
                    h.push(...app.permissions.filter(this.filterPerms).sort(this.alphaSort));
                }
                // if (app.is_admin_device) {
                //     // console.log('- admin_device');
                //     h.push('admin_device');
                // }
            }
        }
        // console.log(h);
        // console.log(hash(h.join(',')));
        return hash(h.join(','));
    }

    private async deviceHash(): Promise<number> {
        if (this.device == null) throw 'Device is null';
        if (this.configuration == null) throw 'Configuration is null';
        // console.log('device', this.device.deviceName, this.configuration.name);
        const h: string[] = [];
        const adb = new Adb(this.device.serial);
        try {
            await adb.connect();
            for (const props in this.configuration.props) {
                const old = await this.device.getProps(adb, props);
                // console.log('props', props, this.configuration.props[props].toString());
                // console.log('props', props, old.toString());
                h.push(props, old.toString());
            }
            if (this.configuration.files) {
                for (const file of this.configuration.files) {
                    const files = await this.device.getFiles(adb, file.path);
                    // console.log('files', ...file.files.sort(this.alphaSort));
                    // console.log('files', ...files.sort(this.alphaSort));
                    h.push(...files.sort(this.alphaSort));
                }
            }
            for (const app of this.configuration.applications.packages) {
                if (this.device.isInstalled(app.pkg)) {
                    // console.log('pkg', app.pkg);
                    h.push(app.pkg);
                }
                const pkg = this.device.packages.find((p) => p.name == app.pkg);
                if (app.files) {
                    for (const files of app.files) {
                        const installedFiles = await this.device.getFiles(adb, files.path);
                        // console.log('- files', ...app.files?.files.sort(this.alphaSort));
                        // console.log('- files', ...files.sort(this.alphaSort));
                        h.push(...installedFiles.sort(this.alphaSort));
                    }
                }
                if (app.permissions != null && pkg) {
                    const installedPerms = app.permissions.filter(
                        (p) => pkg.permissions.indexOf(p) > -1
                    );
                    // console.log('- requested permissions', ...app.permissions.filter(this.filterPerms).sort(this.alphaSort));
                    // console.log('- installed perms', ...installedPerms.filter(this.filterPerms).sort(this.alphaSort));
                    h.push(...installedPerms.filter(this.filterPerms).sort(this.alphaSort));
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            await adb.close();
        }
        // console.log(h);
        // console.log(hash(h.join(',')));
        return hash(h.join(','));
    }

    private alphaSort = (a: string, b: string): number => (a < b ? -1 : 1);

    private filterPerms = (p: string): boolean => {
        return (
            [
                'android.permission.READ_EXTERNAL_STORAGE',
                'android.permission.WRITE_EXTERNAL_STORAGE'
            ].indexOf(p) == -1
        );
    };
}

import { getEnv } from '@/utility/env';

export class Log {
    public static writeLog = true;

    public static debug(payload: any) {
        this.send('debug', payload).catch(console.error);
    }

    public static warn(payload: any) {
        this.send('warn', payload).catch(console.error);
    }

    public static error(payload: any) {
        this.send('error', payload).catch(console.error);
    }

    private static async send(level: string, data: any) {
        const token = getEnv('APP_LOGS_TOKEN');
        this.writeLog = location.hostname == 'localhost' || token == '';
        if (this.writeLog) {
            if (level == 'error') console.error(data);
            else console.log(data);
        } else if (location.hostname != 'localhost') {
            data.level = level;
            const url = 'https://logs.butterfly-tx.com/api/default/default/_json';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Basic ${btoa(`admin@butterfly-tx.com:${token}`)}`
                },
                body: JSON.stringify([data])
            }).catch(console.error);
        }
    }
}

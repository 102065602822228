<script lang="ts" src="./App.ts"></script>

<template>
    <div class="container pt-6">
        <div class="content">
            <nav aria-label="main navigation" class="navbar" role="navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" href="https://adb.butterfly-tx.com">
                        <img alt="logo" class="logo" src="/logo.svg" />
                    </a>
                </div>
                <div class="navbar-menu">
                    <div class="navbar-start">
                        <h1 class="title mt-4">Butterfly Therapeutics</h1>
                    </div>
                </div>
            </nav>
            <router-view />
        </div>
    </div>
    <div class="version notification is-rounded p-2 m-4">
        Version: {{ version }} / Build: {{ build }}
    </div>
</template>

<style lang="scss" src="./App.scss"></style>

<script lang="ts" src="./Phone.ts"></script>

<template>
    <div v-if="error != null">
        <h2>
            <font-awesome-icon :icon="warning()" class="red" />
            {{ error }}
        </h2>
        <p>Veuillez contacter le support, merci</p>
    </div>
    <div v-else-if="device != null && deviceLoaded">
        <div v-if="!deviceReady">
            <div v-if="configuration">
                <font-awesome-icon
                    :class="{ red: !configApplied, green: configApplied }"
                    :icon="check()"
                />
                Configuration : <b>{{ configuration.name }}</b>
            </div>
            <div v-else>
                <font-awesome-icon class="red" :icon="check()" />
                Aucune configuration pour ce périphérique
            </div>
            <div class="field mb-0">
                <input
                    id="override"
                    v-model="override"
                    :disabled="status != ''"
                    type="checkbox"
                    class="switch is-rounded is-warning"
                />
                <label class="label mt-4" for="override">Écraser la configuration existante</label>
            </div>
            <button :disabled="status != ''" class="button is-primary mt-4" @click="setupDevice()">
                Mettre à jour et appliquer la configuration
            </button>
            <div v-if="status" class="box mt-4 is-bordered">
                <h2 class="subtitle">{{ status }}</h2>
                <p class="content">Merci de patienter...</p>
                <progress v-if="!progress" class="progress is-large is-primary" />
                <progress v-else :value="progress" class="progress is-large is-primary" max="100" />
            </div>
        </div>
        <div v-else>
            <h2>Votre téléphone est prêt à être utilisé.</h2>
            <p>Toute l'équipe de Butterfly Therapeutics vous remercie de votre confiance.</p>
            <div>
                <button class="button is-primary mt-4" @click="resetDevice()">
                    Réinitialiser de la configuration
                </button>
                <button
                    :disabled="status != ''"
                    class="button is-danger is-light is-pulled-right"
                    @click="reboot()"
                >
                    Redémarrer le téléphone
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>

<script lang="ts" src="./PicoG2.ts"></script>

<template>
    <div v-if="error != null">
        <h2>
            <font-awesome-icon :icon="warning()" class="red" />
            {{ error }}
        </h2>
        <p>Veuillez contacter le support, merci</p>
    </div>
    <div v-else-if="device != null && deviceLoaded">
        <div v-if="!hasRequestVersion()">
            <h2>
                <font-awesome-icon :icon="warning()" class="red" />
                Une mise à jour de votre {{ device.name }} est requise
            </h2>
            <p v-if="configuration">
                La version <strong>{{ configuration.minVersionFirmware }}</strong> est requise
            </p>
            <p>Merci de mettre à jours le firmware de votre {{ device.name }}</p>
        </div>
        <div v-else-if="!device.hasServerLicense">
            <h2>
                <font-awesome-icon :icon="warning()" class="red" />
                Aucune licence disponible sur notre serveur pour votre périphérique
            </h2>
            <p>
                Merci de contacter notre
                <a href="mailto:support@butterfly-tx.com" target="_blank">support commercial</a>
            </p>
        </div>
        <div v-else-if="!deviceReady">
            <div v-if="!device.hasServerLicense">
                <font-awesome-icon :icon="check()" class="red" />
                Nous n'avons pas trouvé de licence pour votre casque sur notre serveur
            </div>
            <div v-else>
                <div>
                    <font-awesome-icon :icon="check()" class="green" />
                    Nous avons trouvé une licence pour votre casque sur notre serveur
                </div>
                <div v-if="!device.hasDeviceLicense">
                    <font-awesome-icon :icon="check()" class="red" />
                    La licence n'est pas installé sur le casque
                </div>
                <div v-else-if="device.hasLicenseToUpdate">
                    <font-awesome-icon :icon="check()" class="orange" />
                    La licence de votre périphérique doit être mise à jour
                </div>
                <div v-else>
                    <font-awesome-icon :icon="check()" class="green" />
                    La licence de votre périphérique est à jour
                </div>
            </div>
            <div v-if="configuration">
                <font-awesome-icon
                    :class="{ orange: !configApplied, green: configApplied }"
                    :icon="check()"
                />
                Configuration : <b>{{ configuration.name }}</b>
            </div>
            <div v-else>
                <font-awesome-icon class="red" :icon="check()" /> Aucune configuration pour ce
                périphérique
            </div>
            <div v-if="!device.hasTimeUpToDate">
                <font-awesome-icon :icon="check()" class="red" />
                La date du périphérique n'est pas à jour
            </div>
            <div v-else>
                <font-awesome-icon :icon="check()" class="green" />
                La date du périphérique est à jour
            </div>
            <div>
                <div class="field mb-0">
                    <input
                        id="override"
                        v-model="override"
                        :disabled="status != ''"
                        type="checkbox"
                        class="switch is-rounded is-warning"
                    />
                    <label class="label mt-4" for="override"
                        >Écraser la configuration existante (licence, applications)</label
                    >
                </div>
                <div class="field" v-if="override">
                    <input
                        id="overrideLarges"
                        v-model="overrideLargesFiles"
                        :disabled="status != ''"
                        type="checkbox"
                        class="switch is-rounded is-danger"
                    />
                    <label class="label has-text-weight-normal" for="overrideLarges">
                        Écraser également les fichiers lourds (audios et vidéos)
                    </label>
                </div>
                <button :disabled="status != ''" class="button is-primary" @click="setupDevice()">
                    Mettre à jour et appliquer la configuration
                </button>
                <button :disabled="status != ''" class="button is-warning" @click="rebootDevice()">
                    Redemarrer le casque
                </button>
                <div
                    v-if="overrideLargesFiles && status == ''"
                    class="notification is-warning mt-4"
                >
                    <b>Attention</b>, cela nécessite en moyenne <b>40 min</b> pour copier l'ensemble
                    des fichiers...
                </div>
            </div>
            <div v-if="status" class="mt-4">
                <h2 class="subtitle">{{ status }}</h2>
                <p class="content">Merci de patienter...</p>
                <progress v-if="!progress" class="progress is-large is-primary" />
                <progress v-else :value="progress" class="progress is-large is-primary" max="100" />
            </div>
        </div>
        <div v-else>
            <h2>Votre casque est prêt à être utilisé.</h2>
            <p>Toute l'équipe de Butterfly Therapeutics vous remercie de votre confiance.</p>
            <button class="button is-primary mt-4" @click="resetDevice()">
                Réinitialiser de la configuration
            </button>
            <button class="button is-warning mt-4" @click="rebootDevice()">
                Redemarrer le casque
            </button>
            <div class="box mt-4 columns">
                <div class="column" v-if="device.license">
                    <h3>Information sur la licence</h3>
                    <p>
                        Propriétaire:
                        <span class="has-text-weight-bold">{{ device.license.owner }}</span
                        ><br />
                        Usage autorisé:
                        <span class="has-text-weight-bold">{{ device.license.usage }}</span
                        ><br />
                        Date de création de la license:
                        <span class="has-text-weight-bold" v-if="device.license.issued">{{
                            device.license.issued.toLocaleDateString()
                        }}</span>
                    </p>
                </div>
                <div class="column">
                    <h3>Batterie</h3>
                    <label class="label"
                        >Batterie {{ device.battery.technology }} présente et chargée à
                        {{ device.battery.percent }}%</label
                    >
                    <progress
                        class="mt-2 progress is-info is-large"
                        v-if="device.battery.present"
                        max="100"
                        :value="device.battery.percent"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h1>Chargement de la configuration...</h1>
    </div>
</template>

<style lang="scss" scoped></style>

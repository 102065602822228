import { AdbDaemonWebUsbDevice, AdbDaemonWebUsbDeviceManager } from '@yume-chan/adb-daemon-webusb';
import { Adb as AdbDaemon, AdbDaemonTransport } from '@yume-chan/adb';
import AdbWebCredentialStore from '@yume-chan/adb-credential-web';
import { Consumable } from '@yume-chan/stream-extra';
import { EventDispatcher } from '@/utility/events';
import { Log } from '@/api/log/log';

Log.writeLog = true;
const credential: AdbWebCredentialStore = new AdbWebCredentialStore('Butterfly');

export class Adb extends EventDispatcher {
    private readonly serial: string;
    private declare device: AdbDaemonWebUsbDevice;
    private declare adb: AdbDaemon;

    constructor(serial: string) {
        super();
        this.serial = serial;
    }

    public async connect() {
        this.device = await this.getDevice();
        try {
            const conn = await this.device.connect();
            const transport = await AdbDaemonTransport.authenticate({
                serial: conn.device.serial,
                //@ts-ignore
                connection: conn,
                credentialStore: credential
            });
            this.adb = new AdbDaemon(transport);
            Log.debug({ action: 'connected', serial: this.device.serial });
        } catch (e) {
            Log.error({ action: 'connect', serial: this.device.serial, error: `${e}` });
            throw e;
        }
    }

    public close() {
        if (!this.device || !this.adb) return;
        try {
            Log.debug({ action: 'close', serial: this.device.serial });
            return this.adb.close();
        } catch (e) {
            Log.error({ action: 'close', serial: this.device.serial, error: `${e}` });
            throw e;
        }
    }

    public async forget() {
        if (!this.device) return;
        try {
            Log.debug({ action: 'forget', serial: this.device.serial });
            return await this.device.raw.forget();
        } catch (e) {
            Log.error({ action: 'forget', serial: this.device.serial, error: `${e}` });
            throw e;
        }
    }

    public async ensureScreenOn(): Promise<void> {
        const res = await this.shell('dumpsys power');
        const isOn = res.match(/Display Power: state=(ON|OFF)/)![1] == 'ON';
        if (!isOn) await this.shell('input keyevent KEYCODE_WAKEUP');
    }

    public async shell(cmd: string) {
        try {
            let res: string;
            if (cmd.startsWith('date')) res = await this.execShell(cmd);
            else res = (await this.adb.subprocess.spawnAndWaitLegacy(cmd)).trim();
            if (res.indexOf('Error') == 0) throw res;
            Log.debug({ action: 'shell', cmd: cmd, result: res, serial: this.device.serial });
            return res;
        } catch (e) {
            Log.error({ action: 'shell', serial: this.device.serial, error: `${e}` });
            throw e;
        } finally {
            this.adb.subprocess.dispose();
        }
    }

    public async pull(path: string) {
        const sync = await this.adb.sync();
        try {
            const raw = await sync.read(path).getReader().read();
            Log.debug({ action: 'pull', path: path, serial: this.device.serial });
            return new TextDecoder().decode(raw.value);
        } catch (e) {
            if (`${e}`.indexOf('No such file or directory') > -1) throw 'No such file or directory';
            Log.error({ action: 'pull', serial: this.device.serial, error: `${e}` });
            throw e;
        } finally {
            await sync.dispose();
        }
    }

    public async push(file: File, path: string) {
        try {
            const totalBytes = file.size;
            const reader = file.stream().getReader();
            Log.debug({
                action: 'push',
                path: path,
                serial: this.device.serial
            });
            return await this.transmit(reader, path, totalBytes);
        } catch (e) {
            Log.error({ action: 'push', serial: this.device.serial, error: `${e}` });
            throw e;
        }
    }

    public async stream(url: string, path: string, fileName = '') {
        try {
            const resp = await fetch(url + '?' + Math.random());
            if (fileName == '') fileName = url.split('/').pop()!;
            const totalBytes = Number.parseInt(resp.headers.get('Content-Length')!);
            const reader = resp.body!.getReader();
            Log.debug({
                action: 'stream',
                fileName: fileName,
                path: path,
                serial: this.device.serial
            });
            return await this.transmit(reader, `${path}/${fileName}`, totalBytes);
        } catch (e) {
            Log.error({ action: 'stream', serial: this.device.serial, error: `${e}` });
            throw e;
        }
    }

    private async execShell(cmd: string) {
        const result = await this.adb.subprocess.shell(cmd);
        const stream = await result.stdout.getReader().read();
        return new TextDecoder().decode(stream.value).trim();
    }

    private async transmit(
        reader: ReadableStreamDefaultReader<Uint8Array>,
        path: string,
        totalBytes: number
    ) {
        let sentBytes = 0;
        const transmit = async (controller: ReadableStreamDefaultController): Promise<boolean> => {
            const res = await reader.read();
            if (res.done) return false;
            sentBytes += res.value!.length;
            controller.enqueue(new Consumable(res.value));
            this.dispatchEvent(
                new ProgressEvent('progress', { loaded: sentBytes, total: totalBytes })
            );
            return true;
        };
        const fileInput = new ReadableStream({
            async start(controller) {
                try {
                    while (await transmit(controller));
                    controller.close();
                    reader.releaseLock();
                } catch (e) {
                    console.error(e);
                }
            },
            cancel(reason?: string) {
                console.error(reason);
            }
        });
        const sync = await this.adb.sync();
        //@ts-ignore
        await sync.write({ filename: path, file: fileInput, permission: 0o644 });
        await sync.dispose();
        return path;
    }

    private async getDevice() {
        const manager = AdbDaemonWebUsbDeviceManager.BROWSER;
        if (!manager) throw 'No ADB over Web USB';
        const devices = await manager.getDevices();
        const device = devices.find((d) => d.serial == this.serial);
        if (device == undefined) {
            Log.error({ action: 'getDevice', serial: this.serial, error: `Device not found` });
            throw 'Phone is undefined';
        }
        return device;
    }

    wait(milliseconds: number) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    // async pushLocalFile(file: File, path: string) {
    //     const reader = new FileReader();
    //     let startSize = 0;
    //     const totalBytes = file.size;
    //     let sentBytes = 0;
    //     const CHUNK_SIZE = 10 * 1024;
    //     const seek = (controller: ReadableStreamDefaultController) => {
    //         if (sentBytes >= file.size) {
    //             controller.close();
    //         } else {
    //             const slice = file.slice(startSize, startSize + CHUNK_SIZE);
    //             reader.readAsArrayBuffer(slice);
    //             startSize += CHUNK_SIZE;
    //         }
    //         this.dispatchEvent(new ProgressEvent('progress', { loaded: sentBytes, total: totalBytes }));
    //     };
    //     const load = (controller: ReadableStreamDefaultController) => {
    //         const buffer = new Uint8Array(reader.result as ArrayBuffer);
    //         controller.enqueue(new Consumable(buffer));
    //         sentBytes += buffer.byteLength;
    //         seek(controller);
    //     };
    //     const fileInput = new ReadableStream({
    //         start(controller) {
    //             reader.addEventListener('load', () => load(controller));
    //             seek(controller);
    //         }
    //     });
    //     const sync = await this.adb.sync();
    //     // @ts-ignore
    //     await sync.write({ filename: path, file: fileInput, permission: 0o644 });
    //     await sync.dispose();
    //     return { size: sentBytes, path: path };
    // }

    // async streamFile(from: string, path: string) {
    //     const name = from.split('/').pop();
    //     const resp = await fetch(from);
    //     const reader = resp.body!.getReader();
    //     const totalBytes = Number.parseInt(resp.headers.get('Content-Length')!);
    //     let sentBytes = 0;
    //     let target: string | null = `${path}/${name}`;
    //     const transmit = async (controller: ReadableStreamDefaultController) => {
    //         const res = await reader.read();
    //         if (res.done) return false;
    //         sentBytes += res.value!.length;
    //         controller.enqueue(new Consumable(res.value));
    //         return true;
    //     };
    //     const fileInput = new ReadableStream({
    //         async start(controller) {
    //             try {
    //                 while (await transmit(controller));
    //                 controller.close();
    //                 reader.releaseLock();
    //             } catch (e) {
    //                 console.error(e);
    //                 target = null;
    //             }
    //         },
    //         cancel(reason?: string) {
    //             console.error(reason);
    //             target = null;
    //         }
    //     });
    //     const sync = await this.adb.sync();
    //     //@ts-ignore
    //     await sync.write({ filename: target, file: fileInput, permission: 0o644 });
    //     await sync.dispose();
    //     return { size: sentBytes, path: target };
    // }
}

interface EventMap {
    progress: ProgressEvent;
}

interface EventEmitter {
    addEventListener<E extends keyof EventMap>(type: E, listener: (ev: EventMap[E]) => any): void;

    removeEventListener<E extends keyof EventMap>(
        type: E,
        listener: (ev: EventMap[E]) => any
    ): void;

    dispatchEvent<E extends keyof EventMap>(ev: EventMap[E]): void;
}

export class EventDispatcher implements EventEmitter {
    private readonly listeners: Map<string, any> = new Map<string, any>();

    addEventListener<E extends keyof EventMap>(type: E, listener: (ev: EventMap[E]) => any): void {
        let ll = this.listeners.get(type);
        if (!ll) ll = new Array<(evt: Event) => any>();
        ll.push(listener);
        this.listeners.set(type, ll);
    }

    removeEventListener<E extends keyof EventMap>(
        type: E,
        listener: (ev: EventMap[E]) => any
    ): void {
        let ll = this.listeners.get(type);
        if (!ll) return;
        const i = ll.findIndex((l: any) => l === listener);
        if (i > -1) ll = ll.splice(i, 1);
        this.listeners.set(type, ll);
    }

    dispatchEvent<E extends keyof EventMap>(ev: EventMap[E]): void {
        if (!this.listeners.has(ev.type)) return;
        const ll = this.listeners.get(ev.type);
        ll!.forEach((l: any) => l(ev));
    }
}

import { Options, Vue } from 'vue-class-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { Adb } from '@/api/adb/adb';
import { DeviceType, PicoG2Device } from '@/entities/device';
import { Config, Configuration, Files } from '@/entities/configuration';
import { AdbDaemonWebUsbDeviceManager } from '@yume-chan/adb-daemon-webusb';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { hashConfig, configMap, deviceMap, filterFiles } from '@/utility/devices';
import { Log } from '@/api/log/log';
import { timeConversion } from '@/utility/time';
import { getConfigUrl } from '@/utility/config';

@Options({
    props: {
        serial: String
    },
    methods: {
        check() {
            return faCheckCircle;
        },
        warning() {
            return faBolt;
        }
    },
    components: { FontAwesomeIcon }
})
export default class PicoG2 extends Vue {
    public declare serial: string;
    public declare check: () => IconDefinition;
    public declare warning: () => IconDefinition;
    public device: PicoG2Device | null = null;
    public configuration: Configuration | null = null;
    public deviceFiles: Files[] = [];
    public deviceLoaded = false;
    public allKnownPackages: string[] = [];
    public deviceReady = false;
    public configApplied = false;
    public status = 'Chargement de la configuration';
    public error: string | null = null;
    public progress: number | null = null;
    public override = false;
    public overrideLargesFiles = false;

    mounted() {
        this.getDevice().catch(console.error);
    }

    updateProgress(e: ProgressEvent) {
        const pc = (e.loaded * 100) / e.total;
        this.progress = pc == 100 ? null : pc;
    }

    async resetDevice() {
        if (this.device == null) throw 'Device is null';
        if (this.configuration == null) throw 'Configuration is null';
        this.deviceReady = false;
    }

    private async getDevice() {
        try {
            const manager = AdbDaemonWebUsbDeviceManager.BROWSER;
            if (!manager) throw "Le périphérique USB n'est pas disponible";
            const device = (await manager.getDevices()).find((d) => d.serial == this.serial);
            if (device != null) {
                this.device = new PicoG2Device(device.name, device.serial, device);
                await this.loadDeviceInfo();
            }
        } catch (e) {
            Log.error({ action: 'load', serial: this.serial, error: `${e}` });
            this.device = null;
        } finally {
            this.deviceLoaded = true;
            this.status = '';
        }
    }

    private async loadDeviceInfo() {
        if (this.device == null) return;
        await this.device.loadInfo();
        await this.loadConfig(DeviceType.PicoG2, this.serial);
        await this.loadPackagesInfo();
        await this.deviceIsReady();
    }

    private async loadConfig(deviceType: DeviceType, serial: string) {
        this.configuration = null;
        this.deviceFiles = [];
        try {
            const resp = await fetch(getConfigUrl());
            const config = (await resp.json()) as Config;
            this.allKnownPackages = config.configs
                .map((c) => c.applications.packages)
                .map((pkgs) => pkgs.map((p) => p.pkg))
                .reduce((pkgs, value) => pkgs.concat(value), []);
            const configs = config.configs.filter((c) => c.deviceType == deviceType.toString());
            if (serial) {
                const device = config.devices.find((d) => d.serial == serial);
                if (device) {
                    this.configuration = configs.find((c) => c.id == device.config) ?? null;
                    this.deviceFiles = device.files;
                }
            }
            if (!this.configuration) {
                this.configuration = configs.find((c) => c.visibility == 'public') ?? null;
            }
        } catch (e: any) {
            this.error = 'Impossible de charger les configurations';
        }
    }

    private async loadPackagesInfo() {
        if (!this.device || !this.configuration) return;
        const pkgs = this.configuration.applications.packages.map((p) => p.pkg);
        await this.device.loadPackagesInfo(pkgs);
    }

    private async deviceIsReady() {
        if (!this.device || !this.configuration) return;
        const config = await configMap(this.configuration);
        const device = await deviceMap(this.device, this.device.currentConfig, this.configuration);
        // console.log('config', config);
        // console.log('device', device);
        this.configApplied = hashConfig(config) == hashConfig(device);
        // console.log('configApplied', this.configApplied);
        // console.log('hasDeviceLicense', this.device.hasDeviceLicense);
        // console.log('hasLicenseToUpdate', this.device.hasLicenseToUpdate);
        // console.log('hasTimeUpToDate', this.device.hasTimeUpToDate);
        if (!this.device.hasDeviceLicense) this.deviceReady = false;
        else if (this.device.hasLicenseToUpdate) this.deviceReady = false;
        else if (!this.device.hasTimeUpToDate) this.deviceReady = false;
        else this.deviceReady = this.configApplied;
    }

    hasRequestVersion() {
        if (!this.device || !this.configuration || !this.configuration.minVersionFirmware)
            return true;
        const dsv = Number(this.device.systemVersion.replaceAll('.', ''));
        const rsv = Number(this.configuration.minVersionFirmware.replaceAll('.', ''));
        return dsv >= rsv;
    }

    async rebootDevice() {
        if (this.device == null) throw 'Device is null';
        await this.device.reboot();
    }

    async setupDevice() {
        if (this.device == null) throw 'Device is null';
        if (this.configuration == null) throw 'Configuration is null';
        const startTime = Date.now();
        Log.debug({
            action: 'setup start',
            serial: this.device.serial,
            configuration: this.configuration.name
        });
        this.status = 'Configuration en cours';
        const adb = new Adb(this.device.serial);
        let isOnError = false;
        try {
            await adb.connect();

            await this.uninstallUnnecessaryApps(adb);

            // License
            if (
                this.device.hasServerLicense &&
                (this.override || !this.device.hasDeviceLicense || this.device.hasLicenseToUpdate)
            ) {
                this.status = 'Installation de la licence';
                await this.device.pushDeviceLicense(adb);
            }

            // Config Props & Files
            for (const props in this.configuration.props) {
                this.status = "Configuration d'Android";
                const old = await this.device.getProps(adb, props);
                const value = this.configuration.props[props];
                if (old != value) await this.device.setProps(adb, props, value);
            }
            if (this.configuration.files) {
                await this.pushFiles(adb, this.configuration.files);
            }

            // Config APPS
            const baseUrl = this.configuration.applications.baseUrl;
            for (const app of this.configuration.applications.packages) {
                if (this.override || !this.device.isInstalled(app.pkg)) {
                    this.status = `Installation de ${app.name}`;
                    adb.addEventListener('progress', this.updateProgress);
                    try {
                        await this.device.installBundle(
                            adb,
                            baseUrl,
                            app.pkg,
                            app.apks,
                            app.adminReceiver != ''
                        );
                    } catch (e) {
                        if ('install failed' == `${e}`) {
                            if (app.adminReceiver) {
                                const rcv = `${app.pkg}/.${app.adminReceiver}`;
                                await this.device.configureDeviceAdmin(adb, rcv, false);
                            }
                            await this.device.uninstall(adb, app.pkg);
                            await this.device.installBundle(
                                adb,
                                baseUrl,
                                app.pkg,
                                app.apks,
                                app.adminReceiver != ''
                            );
                        }
                    }
                    adb.removeEventListener('progress', this.updateProgress);
                    this.progress = null;
                }
                const pkg = this.device.packages.find((p) => p.name == app.pkg);
                const installedPerms = pkg ? pkg.permissions : [];
                if (app.permissions) {
                    const perms = app.permissions.filter(
                        (p) => this.override || installedPerms.indexOf(p) == -1
                    );
                    for (const perm of perms) {
                        this.status = `Configuration des permissions de ${app.name}`;
                        await this.device.setPermission(adb, app.pkg, perm);
                    }
                }
                if (app.files) {
                    await this.pushFiles(adb, app.files);
                }
            }

            if (this.deviceFiles) {
                await this.pushFiles(adb, this.deviceFiles);
            }

            // Setup
            this.status = 'Configuration finale du casque...';

            if (!this.device.hasTimeUpToDate) {
                await this.device.setDatetime(adb);
            }

            await this.device.writeConfigName(adb, this.configuration.name);
        } catch (e) {
            Log.debug({
                action: 'setup error',
                serial: this.device.serial,
                configuration: this.configuration.name,
                error: `${e}`,
                duration: timeConversion(Date.now() - startTime)
            });
            console.error(e);
            isOnError = true;
        } finally {
            Log.debug({
                action: 'setup complete',
                serial: this.device.serial,
                configuration: this.configuration.name,
                duration: timeConversion(Date.now() - startTime)
            });
            this.status = '';
        }

        try {
            if (!isOnError) await adb.shell('reboot');
            await adb.close();
        } catch (e) {
            console.error(e);
        }
    }

    private async uninstallUnnecessaryApps(adb: Adb) {
        if (this.device == null) throw 'Device is null';
        if (this.configuration == null) throw 'Configuration is null';
        try {
            const mustBeRemoved = this.allKnownPackages
                .filter(
                    (pkg) =>
                        this.configuration!.applications.packages.findIndex((p) => p.pkg == pkg) ==
                        -1
                )
                .filter(
                    (pkg) =>
                        pkg.startsWith('com.butterfly') ||
                        pkg.startsWith('com.Enozone') ||
                        pkg.startsWith('com.effetpapillon') ||
                        pkg.startsWith('com.blissdtx')
                );
            for (const pkg of mustBeRemoved) {
                if (this.device.isInstalled(pkg)) {
                    this.status = `Désinstallation de ${pkg}`;
                    await this.device.uninstall(adb, pkg);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    private async pushFiles(adb: Adb, files: Files[]) {
        if (this.device == null) throw 'Device is null';
        for (const remote of files) {
            const exists = (await this.device.getFiles(adb, remote.path)).filter(
                (f) => remote.files.indexOf(f) > -1
            );
            const missing = filterFiles(
                remote.files,
                exists,
                this.override,
                this.overrideLargesFiles
            );
            if (missing.length > 0) {
                await adb.shell(`mkdir -p ${remote.path}`);
                for (const file of missing) {
                    this.status = `Copie de ${file.split('/').pop()}`;
                    const url = `${remote.baseUrl}/${file}`;
                    adb.addEventListener('progress', this.updateProgress);
                    await adb.stream(url, remote.path);
                    adb.removeEventListener('progress', this.updateProgress);
                    this.progress = null;
                }
            }
        }
    }

    private async setDeviceAdmin(adb: Adb, pkg: string, enabled: boolean) {
        if (this.device == null) throw 'Device is null';
        if (this.configuration == null) throw 'Configuration is null';
        if (enabled) {
            const app = this.configuration.applications.packages.find((p) => p.pkg == pkg);
            if (!app || !app.adminReceiver) return;
            const rcv = `${app.pkg}/${app.pkg}/.${app.adminReceiver}`;
            if (rcv != this.device.deviceAdmin) {
                await this.device.configureDeviceAdmin(adb, rcv, true);
            }
        } else if (this.device.deviceAdmin) {
            const adminPkg = this.device.deviceAdmin.split('/').shift()!;
            if (pkg == adminPkg) {
                await this.device.configureDeviceAdmin(adb, this.device.deviceAdmin, false);
            }
        }
        await adb.wait(100);
    }
}

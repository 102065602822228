import { Options, Vue } from 'vue-class-component';
import { Device, DeviceType } from '@/entities/device';
import {
    AdbDaemonWebUsbDeviceManager,
    AdbDaemonWebUsbDeviceWatcher
} from '@yume-chan/adb-daemon-webusb';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import PicoG2 from '@/components/pico-g2/PicoG2.vue';
import PicoG3 from '@/components/pico-g3/PicoG3.vue';
import Phone from '@/components/phone/Phone.vue';

@Options({
    computed: {
        DeviceType() {
            return DeviceType;
        }
    },
    methods: {
        check() {
            return faCheckCircle;
        },
        warning() {
            return faBolt;
        }
    },
    components: { PicoG2, PicoG3, Phone, FontAwesomeIcon }
})
export default class AutoConfig extends Vue {
    public error = '';
    public status = 'Transfert...';
    public device: Device | null = null;
    public deviceLoaded = false;
    private watcher?: AdbDaemonWebUsbDeviceWatcher;

    get isCompatible() {
        return !!navigator.usb;
    }

    mounted() {
        if (navigator.usb) {
            navigator.usb.getDevices().then(() => {
                this.watcher = new AdbDaemonWebUsbDeviceWatcher(this.handleDevice, navigator.usb);
                this.selectDevice().catch(console.error);
            });
        }
    }

    unmounted() {
        if (this.watcher) this.watcher.dispose();
    }

    handleDevice() {
        this.error = '';
        this.device = null;
        this.deviceLoaded = false;
        this.wait(2000).then(this.selectDevice).catch(console.error);
    }

    wait(milliseconds: number) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    async addDevice() {
        this.error = '';
        this.device = null;
        this.deviceLoaded = false;
        const manager = AdbDaemonWebUsbDeviceManager.BROWSER;
        if (!manager) return console.error('No ADB over Web USB');
        await manager.requestDevice().then(this.selectDevice);
    }

    async selectDevice() {
        try {
            this.error = '';
            const manager = AdbDaemonWebUsbDeviceManager.BROWSER;
            if (!manager) throw "Le périphérique USB n'est pas disponible";
            const devices = (await manager.getDevices()).map(
                (d) => new Device(d.name, d.serial, d)
            );
            if (devices.length == 1) this.device = devices[0];
            if (this.device != null) {
                await this.loadDeviceInfo();
            }
        } catch (e) {
            this.error = `${e}`;
        } finally {
            this.deviceLoaded = true;
        }
    }

    async loadDeviceInfo() {
        if (this.device == null) return;
        await this.device.loadInfo();
    }
}

<script lang="ts" src="./AutoConfig.ts"></script>

<template>
    <div>
        <div v-if="!isCompatible" class="notification is-warning">
            <h2>Impossible d'utiliser l'API USB sur votre navigateur.</h2>
            <p>
                Vous devez utiliser
                <a href="https://www.google.com/intl/fr/chrome/" target="_blank">Google Chrome</a>
                ou
                <a href="https://www.microsoft.com/fr-fr/edge/download" target="_blank"
                    >Microsoft Edge</a
                >
                comme navigateur.
            </p>
            <p>Merci de votre compréhension.</p>
            <h5>Toute l'équipe de Butterfly Therapeutics vous remercie de votre confiance.</h5>
        </div>
        <div v-else-if="device == null" class="box">
            <button class="button is-small" type="button" @click="addDevice">
                Ajouter un périphérique
            </button>
        </div>
        <div v-else-if="device" class="box">
            <article class="media">
                <div class="media-left">
                    <h2 v-if="device.deviceName" class="subtitle is-4">
                        {{ device.manufacturer }} ({{ device.deviceName }})
                    </h2>
                    <h1 class="title">{{ device.modelName }}</h1>
                    <h2 class="subtitle is-5">{{ device.serial }}</h2>
                    <p v-if="device?.deviceName">
                        Version système: <br v-if="device?.systemVersion.length > 10" />{{
                            device.systemVersion
                        }}
                    </p>
                    <p v-if="device?.wifi">
                        Wifi:
                        <span v-if="device?.wifi.enabled">
                            Actif
                            <span v-if="device?.wifi.connected">et connecté</span>
                            <span v-else>et déconnecté</span>
                        </span>
                        <span v-else>Désactivé</span>
                    </p>
                    <p v-if="device?.battery">
                        <span v-if="!device?.battery.present">Aucune batterie détectée</span>
                        <span v-else>Batterie: chargée à {{ device?.battery.percent }}%</span>
                    </p>
                    <p v-if="device?.currentDate">
                        Date courante: {{ device.currentDate.toLocaleDateString() }}
                    </p>
                </div>
                <div v-if="device && deviceLoaded" class="media-content ml-5">
                    <div v-if="error">
                        <div v-if="error.endsWith('Unable to claim interface.')">
                            <h3>
                                <font-awesome-icon :icon="warning()" class="red" />
                                ADB semble être en cours d'utilisation.
                            </h3>
                            <p>
                                Merci de fermer le programme ADB afin de pouvoir utiliser cet outil.
                            </p>
                            <pre>adb kill-server</pre>
                        </div>
                        <div v-else>
                            <h3>
                                <font-awesome-icon :icon="warning()" class="red" />
                                Une erreur inconnue est apparue.
                            </h3>
                            <p>{{ error }}</p>
                        </div>
                    </div>
                    <pico-g2 v-else-if="device.is(DeviceType.PicoG2)" :serial="device.serial" />
                    <pico-g3 v-else-if="device.is(DeviceType.PicoG3)" :serial="device.serial" />
                    <phone v-else-if="device.is(DeviceType.Phone)" :serial="device.serial" />
                </div>
                <div v-else class="media-content ml-5">
                    <h1>Chargement du périphérique...</h1>
                </div>
            </article>
        </div>
    </div>
</template>

<style lang="scss" scoped src="./AutoConfig.scss"></style>

import { createRouter, createWebHistory } from 'vue-router';

import AutoConfig from '@/views/auto-config/AutoConfig.vue';

const routes = [{ name: 'config', path: '/', component: AutoConfig }];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
